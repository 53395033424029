import React from 'react';
import ReactTags, { Tag as ReactTag } from 'react-tag-autocomplete';
import { Tag } from '../../types';

import { TagsInputProps } from './TagsInput.types';

const mapTags = (tags: Tag[]) => {
  return tags.map((tag) => {
    const reactTag: ReactTag = {
      id: tag.id!,
      name: `${tag.key}:${tag.value}`,
    };

    return reactTag;
  });
};

const TagsInput = ({ tags, onTagAdded, onTagDeleted }: TagsInputProps) => {
  return (
    <ReactTags
      tags={mapTags(tags)}
      // @ts-ignore
      onAddition={(tag: ReactTag) => {
        const [key, value] = tag.name.split(':');
        onTagAdded({ key, value });
      }}
      onValidate={(tag: ReactTag) => tag.name.split(':').length === 2}
      onDelete={(index: number) => onTagDeleted(tags[index])}
      allowNew
    />
  );
};

export default TagsInput;
