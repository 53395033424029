import { TimeInterval } from '../types';

export * as api from './api';

export * from './drawing-canvas';

export const getColorFromId = (id: number, alpha: number = 1.0) => {
  const PHI = (1 + Math.sqrt(5)) / 2;
  const hue = Math.floor(id * PHI - Math.floor(id * PHI) * 256);
  return `hsla(${hue}, 90%, 50%, ${alpha})`;
};

export const getVideoDurationString = (duration: number) => {
  const hours: number = Math.floor(duration / 3600);
  const minutes: number = Math.floor((duration - hours * 3600) / 60);
  const seconds: number = Math.floor(duration - hours * 3600 - minutes * 60);

  return (
    (hours ? hours + ':' : '') +
    (minutes < 10 ? '0' + minutes : minutes) +
    ':' +
    (seconds < 10 ? '0' + seconds : seconds)
  ).replace(/^0/, '');
};

export const isInTimeInterval = (currentTime: number, timeInterval: TimeInterval) => {
  return timeInterval.start <= currentTime && timeInterval.end >= currentTime;
};
