import { useEffect } from 'react';

export const useDeleteFeature = (
  selectedFeatureId: number | null,
  deleteFeature: (featureId: number) => Promise<void>,
) => {
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Delete' && selectedFeatureId) {
        deleteFeature(selectedFeatureId);
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [selectedFeatureId, deleteFeature]);
};
