import { RefObject, useCallback, useEffect, useRef } from 'react';

import { DrawingMode, Feature, Point, Size, StimulusType, FeaturePolygon, EditMode } from '../../types';

import { useKeyPress } from '../';
import { useFabricCanvas, useFabricCanvasEvent } from './fabric';

import { fabric } from 'fabric';

import { usePolygonTool, useRectangleTool, useCircleTool } from './tools';
import { useBackground } from './background';
import { useZoom } from './zoom';
import { constrainViewport } from '../../utils';

export const useDrawingCanvas = (
  canvasElement: RefObject<HTMLCanvasElement>,
  videoElement: RefObject<HTMLVideoElement>,
  canvasSize: { width: number; height: number },
  drawingMode: DrawingMode,
  editMode: EditMode,
  type: StimulusType,
  src: string,
  size: Size,
  selectedFeature: Feature | null,
  features: Feature[],
  enforceMinimumSize: boolean,
  snapToGrid: boolean,
  zoom: number,
  onFeatureSelected: (featureId: number | null) => void,
  onFeatureAdded: (geometry: Point[]) => void,
  onFeatureGeometrySet: (featureId: number, geometry: Point[]) => void,
  onZoomChanged: (zoom: number) => void,
  onZoomRangeChanged: (zoomRange: { min: number; max: number }) => void,
) => {
  const canvas = useFabricCanvas(canvasElement, canvasSize);

  const spacePressed = useKeyPress(' ');

  const isDown = useRef(false);
  const isPanning = useRef(false);
  const originalX = useRef(0);
  const originalY = useRef(0);

  const onObjectModified = useCallback(
    (event: fabric.IEvent) => {
      if (!event.target) return;

      const rect = event.target as FeaturePolygon;

      if (rect.feature) {
        onFeatureGeometrySet(rect.feature.id, rect.geometry(snapToGrid));
      }
    },
    [onFeatureGeometrySet, snapToGrid],
  );

  const onObjectMoving = useCallback(
    (event: fabric.IEvent) => {
      if (!event.target) return;

      if (type === StimulusType.Video && videoElement.current) {
        videoElement.current.pause();
      }

      const object = event.target as FeaturePolygon;

      let top = object.top!;
      let left = object.left!;

      top = Math.min(top, size.height - object.getScaledHeight());
      left = Math.min(left, size.width - object.getScaledWidth());

      top = Math.max(top, 0);
      left = Math.max(left, 0);

      object.top = top;
      object.left = left;

      object.setCoords();
    },
    [size, type, videoElement],
  );

  const onObjectScaling = useCallback(
    (event: fabric.IEvent) => {
      if (!event.target) return;

      if (type === StimulusType.Video && videoElement.current) {
        videoElement.current.pause();
      }

      const obj = event.target as FeaturePolygon;

      if (obj.left! < 0 || obj.left! + obj.getScaledWidth() > size.width) {
        obj.set('scaleX', (obj as any).lastScaleX ?? obj.scaleX);
        obj.set('left', 0);
      }

      if (obj.top! < 0 || obj.top! + obj.getScaledHeight() > size.height) {
        obj.set('scaleY', (obj as any).lastScaleY ?? obj.scaleY);
        obj.set('top', 0);
      }

      (obj as any).lastScaleX = obj.scaleX;
      (obj as any).lastScaleY = obj.scaleY;

      obj.setCoords();
    },
    [size, type, videoElement],
  );

  const onObjectScaled = useCallback(
    (event: fabric.IEvent) => {
      if (!event.target) return;

      const object = event.target as FeaturePolygon;
      const boundingRect = object.getBoundingRect(true);

      let width = boundingRect.width;
      let height = boundingRect.height;

      if (object.left! + boundingRect.width > size.width) {
        width -= object.left! + boundingRect.width - size.width;
      }

      if (object.top! + boundingRect.height > size.height) {
        height -= object.top! + boundingRect.height - size.height;
      }

      if (object.top! < 0) {
        height += object.top!;
        object.top = 0;
      }

      if (object.left! < 0) {
        width += object.left!;
        object.left = 0;
      }

      object.scaleX = width / object.width!;
      object.scaleY = height / object.height!;
    },
    [size],
  );

  const onSelectionCreated = useCallback(
    (event: fabric.IEvent) => {
      if (!event.target) return;

      const rect = event.target as FeaturePolygon;

      if (event.e && event.e.type !== 'effect') {
        if (type === StimulusType.Video && videoElement.current) {
          videoElement.current.pause();
        }
      }

      if (rect.feature) {
        onFeatureSelected(rect.feature.id);
      }
    },
    [onFeatureSelected, type, videoElement],
  );

  const onSelectionUpdated = useCallback(
    (event: fabric.IEvent) => {
      if (!event.target) return;

      const rect = event.target as FeaturePolygon;

      if (event.e && event.e.type !== 'effect') {
        if (type === StimulusType.Video && videoElement.current) {
          videoElement.current.pause();
        }
      }

      if (rect.feature) {
        onFeatureSelected(rect.feature.id);
      }
    },
    [onFeatureSelected, type, videoElement],
  );

  const onSelectionCleared = useCallback(
    (event: fabric.IEvent) => {
      onFeatureSelected(null);
    },
    [onFeatureSelected],
  );

  const onMouseDown = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas) return;

      const { x, y } = canvas.getPointer(event.e);

      isDown.current = true;

      originalX.current = x;
      originalY.current = y;

      if (spacePressed) {
        isPanning.current = true;
        (canvas as any).lastPosX = (event.e as any).clientX;
        (canvas as any).lastPosY = (event.e as any).clientY;
      } else if (drawingMode !== DrawingMode.Select) {
        if (x > 0 && x < size.width && y > 0 && y < size.height) {
          canvas.setCursor('crosshair');
        } else {
          canvas.setCursor('default');
          if (drawingMode !== DrawingMode.Polygon) {
            return;
          }
        }

        canvas.fire('tool:mouse:down', event);

        if (type === StimulusType.Video) {
          videoElement.current?.pause();
        }
      }
    },
    [canvas, drawingMode, size, spacePressed, type, videoElement],
  );

  const onMouseMove = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas) return;

      const { x, y } = canvas.getPointer(event.e);

      if (isPanning.current) {
        const e = event.e as any;
        const vpt = (canvas as any).viewportTransform!;
        vpt[4] += e.clientX - (canvas as any).lastPosX;
        vpt[5] += e.clientY - (canvas as any).lastPosY;

        canvas.requestRenderAll();

        (canvas as any).lastPosX = e.clientX;
        (canvas as any).lastPosY = e.clientY;

        constrainViewport(canvas, canvasSize, size);
      } else if (drawingMode !== DrawingMode.Select) {
        if (x > 0 && x < size.width && y > 0 && y < size.height) {
          canvas.setCursor('crosshair');
        } else {
          canvas.setCursor('default');
        }

        canvas.fire('tool:mouse:move', event);

        canvas.renderAll();
      }
    },
    [canvas, canvasSize, drawingMode, size],
  );

  const onMouseUp = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas) return;

      isDown.current = false;

      const { x, y } = canvas.getPointer(event.e);

      isPanning.current = false;
      canvas.setViewportTransform(canvas.viewportTransform!);

      if (drawingMode !== DrawingMode.Select) {
        if (x > 0 && x < size.width && y > 0 && y < size.height) {
          canvas.setCursor('crosshair');
        } else {
          canvas.setCursor('default');
        }

        canvas.fire('tool:mouse:up', event);
      }
    },
    [canvas, drawingMode, size],
  );

  useFabricCanvasEvent(canvas, 'object:modified', onObjectModified);
  useFabricCanvasEvent(canvas, 'object:moving', onObjectMoving);
  useFabricCanvasEvent(canvas, 'object:scaling', onObjectScaling);
  useFabricCanvasEvent(canvas, 'object:scaled', onObjectScaled);
  useFabricCanvasEvent(canvas, 'selection:created', onSelectionCreated);
  useFabricCanvasEvent(canvas, 'selection:updated', onSelectionUpdated);
  useFabricCanvasEvent(canvas, 'selection:cleared', onSelectionCleared);
  useFabricCanvasEvent(canvas, 'mouse:down', onMouseDown);
  useFabricCanvasEvent(canvas, 'mouse:move', onMouseMove);
  useFabricCanvasEvent(canvas, 'mouse:up', onMouseUp);

  // Features
  useBackground(canvas, videoElement, type, src, size);
  useZoom(canvas, canvasSize, size, zoom, onZoomChanged, onZoomRangeChanged);

  // Tools
  useRectangleTool(
    canvas,
    drawingMode,
    enforceMinimumSize,
    snapToGrid,
    size,
    originalX,
    originalY,
    isDown,
    onFeatureAdded,
  );

  useCircleTool(
    canvas,
    drawingMode,
    enforceMinimumSize,
    snapToGrid,
    size,
    originalX,
    originalY,
    isDown,
    onFeatureAdded,
  );

  usePolygonTool(canvas, drawingMode, enforceMinimumSize, snapToGrid, size, onFeatureAdded);

  useEffect(() => {
    if (!canvas) return;

    canvas.remove(...canvas.getObjects());

    features.forEach((feature) => {
      const object = new FeaturePolygon(feature);

      if (drawingMode !== DrawingMode.Select) {
        object.selectable = false;
      }

      canvas.add(object);
    });
  }, [canvas, drawingMode, features]);

  // Toggle between move points and edit scale edit modes
  useEffect(() => {
    if (!canvas) return;

    switch (editMode) {
      case EditMode.EditScale:
        canvas.forEachObject((shape) => {
          shape.cornerStyle = 'rect';
          shape.cornerColor = 'rgb(0,186,219)';
          shape.borderColor = 'rgb(0,186,219)';
          shape.hasBorders = true;
          // @ts-ignore
          shape.controls = fabric.Object.prototype.controls;
        });
        break;
      case EditMode.MovePoints:
        canvas.forEachObject((shape) => {
          shape.cornerStyle = 'circle';
          shape.cornerColor = 'rgb(0,186,219)';
          shape.hasBorders = false;

          // @ts-ignore
          const lastControl = (shape as FeaturePolygon).points.length - 1;

          // @ts-ignore
          shape.controls = (shape as FeaturePolygon).points.reduce((acc, point, index) => {
            // @ts-ignore
            acc['p' + index] = new fabric.Control({
              positionHandler: (_: any, __: any, fabricObject: any) => {
                const x = fabricObject.points[index].x - fabricObject.pathOffset.x;
                const y = fabricObject.points[index].y - fabricObject.pathOffset.y;
                return fabric.util.transformPoint(
                  new fabric.Point(x, y),
                  fabric.util.multiplyTransformMatrices(
                    fabricObject.canvas.viewportTransform,
                    fabricObject.calcTransformMatrix(),
                  ),
                );
              },
              actionHandler: (_: any, transform: any, x: number, y: number) => {
                const anchorIndex = index > 0 ? index - 1 : lastControl;

                const fabricObject = transform.target;
                const absolutePoint = fabric.util.transformPoint(
                  // @ts-ignore
                  {
                    x: fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
                    y: fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y,
                  },
                  fabricObject.calcTransformMatrix(),
                );

                const polygon = transform.target;
                const currentControl = polygon.controls[polygon.__corner];
                const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
                const polygonBaseSize = polygon._getNonTransformedDimensions();
                const polygonSize = polygon._getTransformedDimensions(0, 0);

                const finalPointPosition = {
                  x: (mouseLocalPosition.x * polygonBaseSize.x) / polygonSize.x + polygon.pathOffset.x,
                  y: (mouseLocalPosition.y * polygonBaseSize.y) / polygonSize.y + polygon.pathOffset.y,
                };

                if (finalPointPosition.x < 0) {
                  finalPointPosition.x = 0;
                }

                if (finalPointPosition.x > size.width) {
                  finalPointPosition.x = size.width;
                }

                if (finalPointPosition.y < 0) {
                  finalPointPosition.y = 0;
                }

                if (finalPointPosition.y > size.height) {
                  finalPointPosition.y = size.height;
                }

                polygon.points[currentControl.pointIndex] = finalPointPosition;
                polygon.setCoords();

                const polygonBaseSizeAfter = fabricObject._getNonTransformedDimensions();

                const newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSizeAfter.x;
                const newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSizeAfter.y;

                fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
                fabricObject.setCoords();
                return true;
              },
              actionName: 'modifyPolygon',
              pointIndex: index,
            });
            return acc;
          }, {});
        });
        break;
    }

    canvas.requestRenderAll();
  }, [canvas, editMode, drawingMode, features, size]);

  // Discard selection when spacebar is pressed
  useEffect(() => {
    if (!canvas) return;

    if (spacePressed) {
      if (isDown.current) return;

      canvas.selection = false;

      canvas.forEachObject((shape) => {
        shape.selectable = false;
      });

      canvas.discardActiveObject();
      canvas.renderAll();
    } else {
      if (drawingMode === DrawingMode.Select) {
        canvas.forEachObject((shape) => {
          shape.selectable = true;
        });

        canvas.selection = false;
      } else {
        canvas.forEachObject((shape) => {
          shape.selectable = false;
        });

        canvas.selection = false;
      }
    }
  }, [canvas, drawingMode, spacePressed]);

  // Enable or disable selection based on the drawing mode.
  useEffect(() => {
    if (!canvas) return;

    if (drawingMode !== DrawingMode.Select) {
      canvas.defaultCursor = 'default';
      canvas.hoverCursor = 'default';

      canvas.selection = false;

      canvas.forEachObject((shape) => {
        shape.selectable = false;
      });

      canvas.discardActiveObject();
      canvas.renderAll();

      if (type === StimulusType.Video && videoElement.current) {
        videoElement.current.pause();
      }
    } else {
      canvas.defaultCursor = 'default';
      canvas.hoverCursor = 'move';

      canvas.forEachObject((shape) => {
        shape.selectable = true;
      });

      canvas.selection = false;
    }
  }, [canvas, drawingMode, type, videoElement]);

  // React to selected feature change in the parent component
  useEffect(() => {
    if (!canvas || drawingMode !== DrawingMode.Select) return;

    if (selectedFeature) {
      const selected = canvas.getObjects().find((rect) => (rect as FeaturePolygon).feature.id === selectedFeature.id);

      if (selected) {
        canvas.setActiveObject(selected, new Event('effect'));
        canvas.renderAll();
      }
    } else {
      canvas.discardActiveObject();
      canvas.renderAll();
    }
  }, [canvas, features, drawingMode, selectedFeature]);

  // Pause and seek video to selected feature
  useEffect(() => {
    if (!canvas) return;

    if (selectedFeature) {
      const selected = canvas.getObjects().find((rect) => (rect as FeaturePolygon).feature.id === selectedFeature.id);

      if (!selected && type === StimulusType.Video && videoElement.current) {
        videoElement.current.pause();
        videoElement.current.currentTime = selectedFeature.timeInterval!.start / 1000;
      }
    }
  }, [canvas, selectedFeature, type, videoElement]);

  return { spacePressed, isDown };
};
