import { fabric } from 'fabric';
import React, { useCallback } from 'react';
import { DrawingMode, Point, Size } from '../../../types';
import { getRegularPolygonPoints } from '../../../utils';
import { useFabricCanvasEvent } from '../fabric';

export const useCircleTool = (
  canvas: fabric.Canvas | null,
  drawingMode: DrawingMode,
  enforceMinimumSize: boolean,
  snapToGrid: boolean,
  size: Size,
  originalX: React.MutableRefObject<number>,
  originalY: React.MutableRefObject<number>,
  isDown: React.MutableRefObject<boolean>,
  onFeatureAdded: (geometry: Point[]) => void,
) => {
  const onMouseDown = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas || drawingMode !== DrawingMode.Circle) return;

      let { x, y } = canvas.getPointer(event.e);

      x = Number(snapToGrid ? Math.round(x) : x.toFixed(2));
      y = Number(snapToGrid ? Math.round(y) : y.toFixed(2));

      x = Math.min(Math.max(x, 0), size.width!);
      y = Math.min(Math.max(y, 0), size.height!);

      const circle = new fabric.Polygon(getRegularPolygonPoints(20, 1, 1), {
        left: originalX.current,
        top: originalY.current,
        selectable: false,
        hasRotatingPoint: false,
        hasBorders: false,
        hasControls: false,
        angle: 0,
        fill: 'rgb(0,186,219)',
        opacity: 0.5,
      });

      canvas.add(circle);
      canvas.setActiveObject(circle);
    },
    [canvas, drawingMode, originalX, originalY, size, snapToGrid],
  );

  const onMouseMove = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas || drawingMode !== DrawingMode.Circle) return;

      let { x, y } = canvas.getPointer(event.e);

      x = Number(snapToGrid ? Math.round(x) : x.toFixed(2));
      y = Number(snapToGrid ? Math.round(y) : y.toFixed(2));

      x = Math.min(Math.max(x, 0), size.width!);
      y = Math.min(Math.max(y, 0), size.height!);

      const polygon = canvas.getActiveObject() as fabric.Polygon;

      if (!polygon || !isDown) return;

      canvas.setCursor('crosshair');

      canvas.discardActiveObject();
      canvas.remove(polygon);

      const circle = new fabric.Polygon(
        getRegularPolygonPoints(20, -(originalX.current - x) / 2, -(originalY.current - y) / 2).map(
          (point) => new fabric.Point(point.x, point.y),
        ),
        {
          left: originalX.current > x ? Math.abs(x) : originalX.current,
          top: originalY.current > y ? Math.abs(y) : originalY.current,
          selectable: false,
          hasRotatingPoint: false,
          hasBorders: false,
          hasControls: false,
          angle: 0,
          fill: 'rgb(0,186,219)',
          opacity: 0.5,
        },
      );

      canvas.add(circle);
      canvas.setActiveObject(circle);
    },
    [canvas, drawingMode, isDown, originalX, originalY, size, snapToGrid],
  );

  const onMouseUp = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas || drawingMode !== DrawingMode.Circle) return;

      let { x, y } = canvas.getPointer(event.e);

      x = Number(snapToGrid ? Math.round(x) : x.toFixed(2));
      y = Number(snapToGrid ? Math.round(y) : y.toFixed(2));

      x = Math.min(Math.max(x, 0), size.width!);
      y = Math.min(Math.max(y, 0), size.height!);

      const polygon = canvas.getActiveObject() as fabric.Polygon;

      if (polygon) {
        polygon.points = polygon.points!.map(
          (point) => new fabric.Point(point.x + originalX.current, point.y + originalY.current),
        );

        if (enforceMinimumSize) {
          const bounds = polygon.getBoundingRect(true);

          polygon.points = polygon.points!.map((point) => {
            let x = point.x - bounds.left;
            let y = point.y - bounds.top;

            let offsetX = 0;
            let offsetY = 0;

            if (bounds.width < 200.0) {
              x = (x / bounds.width) * 200.0;

              if (bounds.left + 200.0 >= size.width) {
                offsetX -= bounds.left + 200.0 - size.width;
              }
            }

            if (bounds.height < 200.0) {
              y = (y / bounds.height) * 200.0;

              if (bounds.top + 200.0 >= size.height) {
                offsetY -= bounds.top + 200.0 - size.height;
              }
            }

            return new fabric.Point(x + bounds.left + offsetX, y + bounds.top + offsetY);
          });
        }

        onFeatureAdded([...polygon.points!]);

        canvas.discardActiveObject();
        canvas.remove(polygon);
      }
    },

    [canvas, drawingMode, enforceMinimumSize, onFeatureAdded, originalX, originalY, size, snapToGrid],
  );

  useFabricCanvasEvent(canvas, 'tool:mouse:down', onMouseDown);
  useFabricCanvasEvent(canvas, 'tool:mouse:move', onMouseMove);
  useFabricCanvasEvent(canvas, 'tool:mouse:up', onMouseUp);
};
