import { useEffect, useState, useMemo } from 'react';
import { Point, Stimulus, Tag, Tag2, TimeInterval } from '../types';
import { api } from '../utils';

export const useStimulus = (id: number, endpoint?: string, tagsEndpoint?: string, token?: string) => {
  api.init(endpoint, tagsEndpoint, token);

  const [error, setError] = useState(false);

  const [stimulus, setStimulus] = useState<Stimulus | null>(null);

  const loading = useMemo(() => stimulus === null && !error, [stimulus, error]);

  const name = useMemo(() => stimulus?.name, [stimulus]);
  const type = useMemo(() => stimulus?.type, [stimulus]);
  const src = useMemo(() => stimulus?.source, [stimulus]);
  const size = useMemo(() => stimulus?.size, [stimulus]);
  const features = useMemo(() => stimulus?.features, [stimulus]);

  useEffect(() => {
    setError(false);
    setStimulus(null);

    api
      .loadStimulus(id)
      .then((stimulus) => {
        setStimulus(stimulus);
      })
      .catch(() => {
        setError(true);
      });
  }, [id]);

  const addFeature = async (geometry: Point[], tags?: Tag[], tags2?: Tag2[]) => {
    if (!stimulus) return;

    const featureId = await api.addFeature(id, geometry, tags);
    const features = [
      ...stimulus.features,
      {
        id: featureId,
        name: `Feature ${featureId}`,
        tags: tags ?? [],
        tags2: tags2 ?? [],
        geometry,
      },
    ];

    setStimulus({
      ...stimulus,
      features: features,
    });
  };

  const deleteFeature = async (featureId: number) => {
    if (!stimulus) return;

    await api.deleteFeature(id, featureId);

    setStimulus({
      ...stimulus,
      features: stimulus.features.filter((feature) => feature.id !== featureId),
    });
  };

  const setFeatureName = (featureId: number, name: string) => {
    if (!stimulus) return;

    const index = stimulus.features.findIndex((feature) => feature.id === featureId);
    const features = [...stimulus.features];

    features[index] = {
      ...features[index],
      name: name,
    };

    api.editFeature(id, featureId, name, features[index].geometry, features[index].timeInterval);

    setStimulus({
      ...stimulus,
      features: features,
    });
  };

  const addFeatureTag = async (featureId: number, tag: Tag) => {
    if (!stimulus) return;

    const index = stimulus.features.findIndex((f) => f.id === featureId);
    const features = [...stimulus.features];

    tag.id = await api.addTag(id, featureId, tag);

    features[index] = {
      ...features[index],
      tags: [...features[index].tags, tag],
    };

    setStimulus({
      ...stimulus,
      features: features,
    });
  };

  const deleteFeatureTag = async (featureId: number, tagId: number) => {
    if (!stimulus) return;

    const index = stimulus.features.findIndex((f) => f.id === featureId);
    const features = [...stimulus.features];

    await api.deleteTag(id, featureId, tagId);

    features[index] = {
      ...features[index],
      tags: features[index].tags.filter((t) => t.id !== tagId),
    };

    setStimulus({
      ...stimulus,
      features: features,
    });
  };

  const addFeatureTag2 = async (featureId: number, tag: Tag2) => {
    if (!stimulus) return;

    const index = stimulus.features.findIndex((f) => f.id === featureId);
    const features = [...stimulus.features];

    tag.id = await api.addTag2(id, featureId, tag);

    features[index] = {
      ...features[index],
      tags2: [...features[index].tags2, tag],
    };

    setStimulus({
      ...stimulus,
      features: features,
    });
  };

  const deleteFeatureTag2 = async (featureId: number, tagId: number) => {
    if (!stimulus) return;

    const index = stimulus.features.findIndex((f) => f.id === featureId);
    const features = [...stimulus.features];

    await api.deleteTag2(id, featureId, tagId);

    features[index] = {
      ...features[index],
      tags2: features[index].tags2.filter((t) => t.id !== tagId),
    };

    setStimulus({
      ...stimulus,
      features: features,
    });
  };

  const setFeatureGeometry = (featureId: number, geometry: Point[]) => {
    if (!stimulus) return;

    const index = stimulus.features.findIndex((feature) => feature.id === featureId);
    const features = [...stimulus.features];

    features[index] = {
      ...features[index],
      geometry: geometry,
    };

    api.editFeature(id, featureId, features[index].name, geometry, features[index].timeInterval);

    setStimulus({
      ...stimulus,
      features: features,
    });
  };

  const setFeatureStartTime = (featureId: number, time: number) => {
    if (!stimulus) return;

    const index = stimulus.features.findIndex((feature) => feature.id === featureId);
    const features = [...stimulus.features];

    let newTime: TimeInterval;

    if (!features[index].timeInterval || features[index].timeInterval!.end <= time) {
      newTime = {
        start: time,
        end: time + 1000,
      };
    } else {
      newTime = {
        start: time,
        end: features[index].timeInterval!.end,
      };
    }

    features[index] = {
      ...features[index],
      timeInterval: newTime,
    };

    api.editFeature(id, featureId, features[index].name, features[index].geometry, newTime);

    setStimulus({
      ...stimulus,
      features: features,
    });
  };

  const setFeatureEndTime = (featureId: number, time: number) => {
    if (!stimulus) return;

    const index = stimulus.features.findIndex((feature) => feature.id === featureId);
    const features = [...stimulus.features];

    let newTime: TimeInterval;

    if (!features[index].timeInterval || features[index].timeInterval!.start >= time) {
      newTime = {
        start: time,
        end: time,
      };
    } else {
      newTime = {
        start: features[index].timeInterval!.start,
        end: time,
      };
    }

    features[index] = {
      ...features[index],
      timeInterval: newTime,
    };

    api.editFeature(id, featureId, features[index].name, features[index].geometry, newTime);

    setStimulus({
      ...stimulus,
      features: features,
    });
  };

  return {
    id,
    name: name!,
    type: type!,
    src: src!,
    size: size!,
    features: features!,
    loading,
    error,
    addFeature,
    deleteFeature,
    setFeatureName,
    addFeatureTag,
    deleteFeatureTag,
    addFeatureTag2,
    deleteFeatureTag2,
    setFeatureGeometry,
    setFeatureStartTime,
    setFeatureEndTime,
  };
};
