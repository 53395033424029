import React, { useEffect, useMemo, useRef, useState } from 'react';

import './LumenFeatureEditor.sass';

import { LumenFeatureEditorProps } from './LumenFeatureEditor.types';

import Toolbar from '../Toolbar/Toolbar';
import DrawingCanvas from '../DrawingCanvas/DrawingCanvas';
import FeatureList from '../FeatureList/FeatureList';

import { useStimulus, useDeleteFeature, useCopyPasteFeature } from '../../hooks';

import { DrawingMode, EditMode, Feature, StimulusType } from '../../types';
import { isInTimeInterval } from '../../utils';
import VideoControls from '../VideoControls/VideoControls';

const LumenFeatureEditor = ({
  stimulusId,
  endpoint,
  tagsEndpoint,
  token,
  enforceMinimumSizeOverride,
}: LumenFeatureEditorProps) => {
  const stimulus = useStimulus(stimulusId, endpoint, tagsEndpoint, token);

  const { id, name, type, src, size, features, loading, error } = stimulus;

  const [drawingMode, setDrawingMode] = useState(DrawingMode.Select);
  const [editMode, setEditMode] = useState(EditMode.EditScale);

  const [selectedFeatureId, setSelectedFeatureId] = useState<number | null>(null);

  const [videoPaused, setVideoPaused] = useState(false);

  const [videoTime, setVideoTime] = useState(0);

  const [enforceMinimumSize, setEnforceMinimumSize] = useState(enforceMinimumSizeOverride ?? false);

  const [snapToGrid, setSnapToGrid] = useState(true);
  const [zoom, setZoom] = useState(0.5);

  const [zoomRange, setZoomRange] = useState({
    min: 0,
    max: 1,
  });

  const videoElement = useRef<HTMLVideoElement>(null);
  const [videoDuration, setVideoDuration] = useState<number | null>(null);

  const filteredFeatures = useMemo(() => {
    return !loading && features
      ? features.filter((feature: Feature) => {
          if (type === StimulusType.Video) {
            if (feature.timeInterval) {
              return isInTimeInterval(videoTime * 1000, feature.timeInterval);
            } else {
              return true;
            }
          } else {
            return true;
          }
        })
      : [];
  }, [loading, features, type, videoTime]);

  const selectedFeature = useMemo(
    () => (!loading && features ? features.find((feature: Feature) => feature.id === selectedFeatureId) ?? null : null),
    [loading, features, selectedFeatureId],
  );

  useEffect(() => {
    setDrawingMode(DrawingMode.Select);
    setSelectedFeatureId(null);
  }, [stimulusId]);

  useEffect(() => {
    setEnforceMinimumSize(enforceMinimumSizeOverride ?? enforceMinimumSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enforceMinimumSizeOverride]);

  useCopyPasteFeature(selectedFeatureId, features, stimulus.addFeature);
  useDeleteFeature(selectedFeatureId, stimulus.deleteFeature);

  return (
    <div className='lumen-feature-editor'>
      {!error && loading && (
        <div className='loading-container'>
          <p>Loading stimulus with id: {id}</p>
        </div>
      )}
      {error && !loading && (
        <div className='error-container'>
          <p>An error occurred while loading stimulus with id: {id}</p>
        </div>
      )}
      {!error && !loading && (
        <Toolbar
          id={id}
          name={name}
          drawingMode={drawingMode}
          editMode={editMode}
          enforceMinimumSize={enforceMinimumSize}
          snapToGrid={snapToGrid}
          zoom={zoom}
          zoomRange={zoomRange}
          onDrawingModeChanged={(mode) => {
            setDrawingMode(mode);
            if (mode !== DrawingMode.Select) {
              setSelectedFeatureId(null);
            }
          }}
          onEditModeChanged={setEditMode}
          onEnforceMinimumSizeChanged={(value) => {
            if (!enforceMinimumSizeOverride) {
              setEnforceMinimumSize(value);
            }
          }}
          onSnapToGridChanged={setSnapToGrid}
          onZoomChanged={setZoom}
        />
      )}
      {!error && !loading && (
        <div className='main-container'>
          <div className='main-sub-container'>
            <DrawingCanvas
              drawingMode={drawingMode}
              editMode={editMode}
              type={type}
              src={src}
              size={size}
              videoElement={videoElement}
              selectedFeature={selectedFeature}
              features={filteredFeatures}
              enforceMinimumSize={enforceMinimumSize}
              snapToGrid={snapToGrid}
              zoom={zoom}
              onFeatureSelected={(featureId) => {
                setSelectedFeatureId(featureId);
              }}
              onFeatureAdded={stimulus.addFeature}
              onFeatureGeometrySet={stimulus.setFeatureGeometry}
              onZoomChanged={setZoom}
              onZoomRangeChanged={setZoomRange}
            />
            <FeatureList
              stimulusType={type}
              selectedFeatureId={selectedFeatureId}
              features={features}
              onFeatureSelected={(featureId) => {
                setSelectedFeatureId(featureId);
                setDrawingMode(DrawingMode.Select);
              }}
              onFeatureDeleted={(featureId) => {
                if (selectedFeatureId === featureId) {
                  setSelectedFeatureId(null);
                }
                stimulus.deleteFeature(featureId);
              }}
              onFeatureNameSet={stimulus.setFeatureName}
              onFeatureStartTimeSet={(featureId) => stimulus.setFeatureStartTime(featureId, videoTime * 1000)}
              onFeatureEndTimeSet={(featureId) => stimulus.setFeatureEndTime(featureId, videoTime * 1000)}
              onFeatureTagAdded={stimulus.addFeatureTag}
              onFeatureTagDeleted={stimulus.deleteFeatureTag}
              onFeatureTag2Added={stimulus.addFeatureTag2}
              onFeatureTag2Deleted={stimulus.deleteFeatureTag2}
            />
          </div>
          {type === StimulusType.Video && (
            <video
              style={{
                display: 'none',
              }}
              muted
              ref={videoElement}
              src={src}
              width={size.width}
              height={size.height}
              onLoadedData={() => {
                const paused = videoElement.current!.paused;

                setVideoPaused(paused);

                if (!paused && drawingMode !== DrawingMode.Select) {
                  setDrawingMode(DrawingMode.Select);
                }

                setVideoDuration(videoElement.current!.duration);
                videoElement.current!.play();
              }}
              onPlay={() => {
                setVideoPaused(false);

                if (drawingMode !== DrawingMode.Select) {
                  setDrawingMode(DrawingMode.Select);
                }
              }}
              onPause={() => {
                setVideoPaused(true);
              }}
              onTimeUpdate={() => setVideoTime(videoElement.current!.currentTime)}
            />
          )}
          {type === StimulusType.Video && videoElement.current && videoDuration && (
            <VideoControls
              videoPaused={videoPaused}
              videoTime={videoTime}
              videoDuration={videoDuration}
              onPause={() => {
                videoElement.current!.pause();
              }}
              onPlay={() => {
                videoElement.current!.play();
              }}
              onStepBack={() => {
                videoElement.current!.pause();
                videoElement.current!.currentTime = videoElement.current!.currentTime - 1 / 30;
              }}
              onStepForward={() => {
                videoElement.current!.pause();
                videoElement.current!.currentTime = videoElement.current!.currentTime + 1 / 30;
              }}
              onTimeUpdate={(time) => {
                videoElement.current!.currentTime = time;
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LumenFeatureEditor;
