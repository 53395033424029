import { fabric } from 'fabric';
import { RefObject, useEffect } from 'react';
import { Size, StimulusType } from '../../types';

export const useBackground = (
  canvas: fabric.Canvas | null,
  videoElement: RefObject<HTMLVideoElement>,
  type: StimulusType,
  src: string,
  size: Size,
) => {
  useEffect(() => {
    if (!canvas) return;

    if (type === StimulusType.Image) {
      canvas.setBackgroundImage(src, () => canvas.renderAll(), {
        width: size.width,
        height: size.height,
      });
    } else {
      canvas.setBackgroundImage(
        new fabric.Image(videoElement.current!, {
          objectCaching: false,
        }),
        () => {},
        {
          width: size.width,
          height: size.height,
        },
      );

      fabric.util.requestAnimFrame(function render() {
        if (canvas.getElement()) {
          canvas.renderAll();
          fabric.util.requestAnimFrame(render);
        }
      });
    }
  }, [canvas, size, src, type, videoElement]);
};
