import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tag2Key, Tag2Value } from '../../types';

import { api } from '../../utils';

import './Tags2Input.sass';

import { Tags2InputProps } from './Tags2Input.types';

const Tags2Input = ({ tags, onTagAdded, onTagDeleted }: Tags2InputProps) => {
  const suggestionsContainer = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const sizer = useRef<HTMLDivElement>(null);

  const [selectedTagKeyId, setSelectedTagKeyId] = useState<number | null>(null);

  const [tagKeys, setTagKeys] = useState<Tag2Key[]>([]);
  const [tagValues, setTagValues] = useState<Tag2Value[]>([]);

  const [query, setQuery] = useState('');
  const [focused, setFocused] = useState(false);

  const [inputWidth, setInputWidth] = useState(0);

  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);

  const suggestions = useMemo(() => {
    if (selectedTagKeyId) {
      const split = query.split(':');

      if (split.length > 1) {
        const query = split[1];

        return (query.length === 0
          ? tagValues
          : tagValues
              .filter((value) => value.value.toLowerCase().includes(query.toLowerCase()))
              .sort((a, b) => a.value.length - b.value.length)
        ).map((value) => {
          return {
            id: value.id,
            value: value.value,
          };
        });
      } else {
        return tagValues.map((value) => {
          return {
            id: value.id,
            value: value.value,
          };
        });
      }
    } else {
      return (query.length === 0
        ? tagKeys
        : tagKeys
            .filter((key) => key.key.toLowerCase().includes(query.toLowerCase()))
            .sort((a, b) => a.key.length - b.key.length)
      ).map((key) => {
        return {
          id: key.id,
          value: key.key,
        };
      });
    }
  }, [tagKeys, tagValues, query, selectedTagKeyId]);

  useEffect(() => {
    const newInputWidth = Math.ceil(sizer.current?.scrollWidth ?? 0) + 12;

    if (newInputWidth !== inputWidth) {
      setInputWidth(newInputWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    setSelectedSuggestionIndex(0);
  }, [suggestions]);

  useEffect(() => {
    if (focused) {
      fetchTagKeys();
    }
  }, [focused]);

  const fetchTagKeys = () => {
    api.getTag2Keys().then((tag2Keys) => {
      setTagKeys(tag2Keys.sort((a, b) => a.key.localeCompare(b.key, 'en', { numeric: true })));
    });
  };

  const fetchTagValues = (keyId: number) => {
    api.getTag2Values(keyId).then((tag2Values) => {
      setTagValues(tag2Values.sort((a, b) => a.value.localeCompare(b.value, 'en', { numeric: true })));
    });
  };

  const onInputChange = (value: string) => {
    if (value.length === 0) {
      fetchTagKeys();
    }

    if (value.includes(':')) {
      const split = value.split(':');

      const key = tagKeys.find((key) => key.key === split[0]);

      if (key) {
        if (!selectedTagKeyId) {
          fetchTagValues(key.id);
          setSelectedTagKeyId(key.id);
        }

        setQuery(key.key + ':' + split[1]);
      } else {
        setQuery(split[0]);
        setSelectedTagKeyId(null);
      }
    } else if (!value.includes(':') && selectedTagKeyId) {
      setSelectedTagKeyId(null);
      setTagValues([]);
      setQuery(value);
    } else {
      setQuery(value);
    }
  };

  const performAction = (suggestionIndex: number) => {
    const split = query.trim().split(':');

    if (split.length === 1) {
      if (suggestions.length > 0) {
        setQuery(suggestions[suggestionIndex].value + ':');

        const key = tagKeys.find((key) => key.id === suggestions[suggestionIndex].id);

        if (key && !selectedTagKeyId) {
          fetchTagValues(key.id);
          setSelectedTagKeyId(key.id);
        }
      }
    } else {
      const key = tagKeys.find((key) => key.key === split[0]);
      const value = tagValues.find((value) => value.value === split[1]);

      if (key) {
        if (value) {
          onTagAdded({
            key: key,
            value: value,
          });

          setQuery('');
          setSelectedTagKeyId(null);
        } else if (!value && suggestions.length > 0) {
          onTagAdded({
            key: key,
            value: {
              id: suggestions[suggestionIndex].id,
              keyId: key.id,
              value: suggestions[suggestionIndex].value,
            },
          });

          setQuery('');
          setSelectedTagKeyId(null);
        }
      }
    }
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();

      performAction(selectedSuggestionIndex);
    }

    if (event.key === 'ArrowUp' || event.key === 'Up') {
      event.preventDefault();

      let newIndex = selectedSuggestionIndex - 1;

      if (newIndex < 0) {
        newIndex = suggestions.length - 1;
      }

      setSelectedSuggestionIndex(newIndex);
    }

    if (event.key === 'ArrowDown' || event.key === 'Down') {
      event.preventDefault();

      let newIndex = selectedSuggestionIndex + 1;

      if (newIndex > suggestions.length - 1) {
        newIndex = 0;
      }

      setSelectedSuggestionIndex(newIndex);
    }
  };

  const onContainerClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (document.activeElement !== event.target) {
      input.current?.focus();
    }

    setFocused(true);
  };

  useEffect(() => {
    const onMouseDown = (event: MouseEvent) => {
      if (suggestionsContainer.current && !suggestionsContainer.current.contains(event.target as Node | null)) {
        setFocused(false);
      }
    };

    window.addEventListener('mousedown', onMouseDown);

    return () => {
      window.removeEventListener('mousedown', onMouseDown);
    };
  });

  return (
    <div className='tags2-container' onClick={onContainerClick}>
      <div className='tags2-tags'>
        {tags.map((tag, index) => (
          <button key={index} className='tags2-tag' onClick={() => onTagDeleted(tags[index])}>
            <span>{`${tag.key.key}:${tag.value.value}`}</span>
          </button>
        ))}
      </div>
      <div className='tags2-search'>
        <input
          ref={input}
          value={query}
          type='text'
          onFocus={() => {
            fetchTagKeys();
          }}
          onChange={(e) => onInputChange(e.target.value)}
          onKeyDown={onInputKeyDown}
          placeholder={'Add new tag'}
          style={{ width: inputWidth }}
        />
        {focused && suggestions.length > 0 && (
          <div ref={suggestionsContainer} className='tags2-suggestions'>
            <ul>
              {suggestions.map((suggestion, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => performAction(index)}
                    className={index === selectedSuggestionIndex ? 'selected' : ''}
                  >
                    {suggestion.value}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div ref={sizer} className='tags2-sizer'>
          {query || 'Add new tag'}
        </div>
      </div>
    </div>
  );
};

export default Tags2Input;
