import { fabric } from 'fabric';
import { useCallback, useEffect, useRef } from 'react';
import { DrawingMode, Point, Size } from '../../../types';
import { useFabricCanvasEvent } from '../fabric';

export const usePolygonTool = (
  canvas: fabric.Canvas | null,
  drawingMode: DrawingMode,
  enforceMinimumSize: boolean,
  snapToGrid: boolean,
  size: Size,
  onFeatureAdded: (geometry: Point[]) => void,
) => {
  const polygonStartingObject = useRef<fabric.Circle | null>(null);
  const polygonObject = useRef<fabric.Polygon | null>(null);
  const polygonLines = useRef<fabric.Line[]>([]);
  const polygonPoints = useRef<Point[]>([]);

  const onMouseWheel = useCallback(
    (_: fabric.IEvent) => {
      if (!canvas) return;

      if (polygonStartingObject.current) {
        polygonStartingObject.current.setRadius(10.0 / canvas.getZoom());
      }

      polygonLines.current.forEach((line) => line.set({ strokeWidth: 3.0 / canvas.getZoom() }));
    },
    [canvas],
  );

  const onMouseDown = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas || drawingMode !== DrawingMode.Polygon) return;

      let { x, y } = canvas.getPointer(event.e);

      x = Number(snapToGrid ? Math.round(x) : x.toFixed(2));
      y = Number(snapToGrid ? Math.round(y) : y.toFixed(2));

      x = Math.min(Math.max(x, 0), size.width!);
      y = Math.min(Math.max(y, 0), size.height!);

      // @ts-ignore
      if (event.target && polygonStartingObject.current && event.target.id === polygonStartingObject.current.id) {
        if (enforceMinimumSize) {
          const polygon = polygonObject.current!;

          const bounds = polygon.getBoundingRect(true);

          polygonPoints.current = polygonPoints.current!.map((point) => {
            let x = point.x - bounds.left;
            let y = point.y - bounds.top;

            let offsetX = 0;
            let offsetY = 0;

            if (bounds.width < 200.0) {
              x = (x / bounds.width) * 200.0;

              if (bounds.left + 200.0 >= size.width) {
                offsetX -= bounds.left + 200.0 - size.width;
              }
            }

            if (bounds.height < 200.0) {
              y = (y / bounds.height) * 200.0;

              if (bounds.top + 200.0 >= size.height) {
                offsetY -= bounds.top + 200.0 - size.height;
              }
            }

            return new fabric.Point(x + bounds.left + offsetX, y + bounds.top + offsetY);
          });
        }

        canvas.remove(polygonStartingObject.current!);
        canvas.remove(polygonObject.current!);
        polygonLines.current.forEach((line) => canvas.remove(line));

        polygonStartingObject.current = null;
        polygonObject.current = null;
        polygonLines.current = [];

        onFeatureAdded([...polygonPoints.current]);

        polygonPoints.current = [];

        return;
      }

      if (!polygonObject.current) {
        const startingObject = new fabric.Circle({
          // @ts-ignore
          id: 'startingPoint',
          left: x,
          top: y,
          selectable: false,
          hasRotatingPoint: false,
          hasBorders: false,
          hasControls: false,
          radius: 10.0 / canvas.getZoom(),
          opacity: 0.0,
          originX: 'center',
          originY: 'center',
        });

        canvas.add(startingObject);
        polygonStartingObject.current = startingObject;

        const polygon = new fabric.Polygon([new fabric.Point(x, y)], {
          fill: 'rgba(0,186,219,0.5)',
          selectable: false,
          evented: false,
          hasRotatingPoint: false,
          hasBorders: false,
          hasControls: false,
          objectCaching: false,
        });

        canvas.add(polygon);
        polygonObject.current = polygon;

        const line = new fabric.Line([x, y, x, y], {
          stroke: 'rgb(0,186,219)',
          strokeWidth: 3.0 / canvas.getZoom(),
          originX: 'center',
          originY: 'center',
          selectable: false,
          evented: false,
          hasRotatingPoint: false,
          hasBorders: false,
          hasControls: false,
        });

        canvas.add(line);
        polygonLines.current?.push(line);
      } else {
        const points = [...polygonObject.current.get('points')!, new fabric.Point(x, y)];

        canvas.remove(polygonObject.current);

        polygonObject.current = new fabric.Polygon(points, {
          fill: 'rgba(0,186,219,0.5)',
          selectable: false,
          evented: false,
          hasRotatingPoint: false,
          hasBorders: false,
          hasControls: false,
          objectCaching: false,
        });

        canvas.add(polygonObject.current);

        const line = new fabric.Line([x, y, x, y], {
          stroke: 'rgb(0,186,219)',
          strokeWidth: 3.0 / canvas.getZoom(),
          originX: 'center',
          originY: 'center',
          selectable: false,
          evented: false,
          hasRotatingPoint: false,
          hasBorders: false,
          hasControls: false,
        });

        canvas.add(line);
        polygonLines.current?.push(line);

        canvas.renderAll();
      }

      polygonPoints.current.push({
        x,
        y,
      });
    },
    [canvas, drawingMode, enforceMinimumSize, onFeatureAdded, size, snapToGrid],
  );

  const onMouseMove = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas || drawingMode !== DrawingMode.Polygon) return;

      let { x, y } = canvas.getPointer(event.e);

      x = Number(snapToGrid ? Math.round(x) : x.toFixed(2));
      y = Number(snapToGrid ? Math.round(y) : y.toFixed(2));

      x = Math.min(Math.max(x, 0), size.width!);
      y = Math.min(Math.max(y, 0), size.height!);

      if (polygonLines.current.length > 0) {
        const line = polygonLines.current[polygonLines.current.length - 1];

        line.set({ x2: x, y2: y });

        canvas.renderAll();
      }

      if (polygonObject.current) {
        const points = [
          ...polygonPoints.current!.map((point) => new fabric.Point(point.x, point.y)),
          new fabric.Point(x, y),
        ];

        polygonObject.current.set({
          points,
        });
      }

      // @ts-ignore
      if (event.target && polygonStartingObject.current && event.target.id === polygonStartingObject.current.id) {
        canvas.setCursor('pointer');
      }
    },

    [canvas, drawingMode, size, snapToGrid],
  );

  useFabricCanvasEvent(canvas, 'viewport:zoom', onMouseWheel);

  useFabricCanvasEvent(canvas, 'tool:mouse:down', onMouseDown);
  useFabricCanvasEvent(canvas, 'tool:mouse:move', onMouseMove);

  useEffect(() => {
    if (!canvas) return;

    if (polygonStartingObject.current) {
      polygonStartingObject.current.setRadius(10.0 / canvas.getZoom());
    }

    polygonLines.current.forEach((line) => line.set({ strokeWidth: 3.0 / canvas.getZoom() }));
  }, [canvas]);

  useEffect(() => {
    if (!canvas) return;

    if (drawingMode !== DrawingMode.Polygon && polygonStartingObject.current && polygonObject.current) {
      canvas.remove(polygonStartingObject.current);
      canvas.remove(polygonObject.current);
      polygonLines.current.forEach((line) => canvas.remove(line));

      polygonStartingObject.current = null;
      polygonObject.current = null;
      polygonLines.current = [];
      polygonPoints.current = [];
    }

    const onKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === 'Escape' &&
        drawingMode === DrawingMode.Polygon &&
        polygonStartingObject.current &&
        polygonObject.current
      ) {
        canvas.remove(polygonStartingObject.current);
        canvas.remove(polygonObject.current);
        polygonLines.current.forEach((line) => canvas.remove(line));

        polygonStartingObject.current = null;
        polygonObject.current = null;
        polygonLines.current = [];
        polygonPoints.current = [];
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [canvas, drawingMode]);
};
