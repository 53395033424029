import axios, { AxiosInstance } from 'axios';
import {
  Stimulus,
  StimulusResponse,
  StimulusType,
  Feature,
  Point,
  Tag,
  TimeInterval,
  Tag2,
  Tag2KeysResponse,
  Tag2ValuesResponse,
  Tag2Key,
  Tag2Value,
  Tag2ValueResponse,
  Tag2KeyResponse,
} from '../types';

let client: AxiosInstance;
let tags2Endpoint: string;

export const init = (endpoint?: string, tagsEndpoint?: string, token?: string) => {
  if (token) {
    client = axios.create({
      baseURL: endpoint ?? '/v1.0/analysis/stimuli',
      headers: {
        'Content-Type': 'application/json',
        authorization: `token ${token}`,
      },
    });
  } else {
    client = axios.create({
      baseURL: endpoint ?? '/v1.0/analysis/stimuli',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  tags2Endpoint = tagsEndpoint ?? '/v1.0/analysis/tags2';
};

export const loadStimulus = async (stimulusId: number) => {
  const response: StimulusResponse = (await client.get(`/${stimulusId.toString()}`)).data;

  const stimulus: Stimulus = {
    id: response.id,
    name: response.name,
    type: response.type === 'video' ? StimulusType.Video : StimulusType.Image,
    source: response.source,
    features: response.features.map((featureResponse) => {
      const feature: Feature = {
        id: featureResponse.id,
        name: featureResponse.name,
        geometry: featureResponse.geometry.map((geometryResponse) => {
          const point: Point = {
            x: geometryResponse.x,
            y: geometryResponse.y,
          };

          return point;
        }),
        tags: featureResponse.tags.map((tagResponse) => {
          const tag: Tag = {
            id: tagResponse.id,
            key: tagResponse.key,
            value: tagResponse.value,
          };

          return tag;
        }),
        tags2: featureResponse.tags2.map((tagResponse) => {
          const tag: Tag2 = {
            id: tagResponse.id,
            key: {
              id: tagResponse.key.id,
              key: tagResponse.key.key,
            },
            value: {
              id: tagResponse.value.id,
              keyId: tagResponse.value.key_id,
              value: tagResponse.value.value,
            },
          };

          return tag;
        }),
      };

      if (featureResponse.start_time_ms !== undefined && featureResponse.end_time_ms !== undefined) {
        feature.timeInterval = {
          start: featureResponse.start_time_ms,
          end: featureResponse.end_time_ms,
        };
      }

      return feature;
    }),
    size: {
      width: response.width,
      height: response.height,
    },
  };

  return stimulus;
};

export const addFeature = async (stimulusId: number, geometry: Point[], tags?: Tag[]): Promise<number> => {
  const response = await client.post(`/${stimulusId}/features`, {
    geometry,
    tags,
  });

  return response.data.id;
};

export const editFeature = async (
  stimulusId: number,
  featureId: number,
  name: string,
  geometry: Point[],
  timeInterval?: TimeInterval,
) => {
  const body: any = {
    name,
    geometry,
  };

  if (timeInterval) {
    body.start_time_ms = timeInterval.start.toFixed(0);
    body.end_time_ms = timeInterval.end.toFixed(0);
  }

  await client.patch(`/${stimulusId}/features/${featureId}`, body);
};

export const deleteFeature = async (stimulusId: number, featureId: number) => {
  await client.delete(`/${stimulusId}/features/${featureId}`);
};

export const addTag = async (stimulusId: number, featureId: number, tag: Tag): Promise<number> => {
  const response = await client.post(`/${stimulusId}/features/${featureId}/tags`, {
    key: tag.key,
    value: tag.value,
  });

  return response.data.id;
};

export const deleteTag = async (stimulusId: number, featureId: number, tagId: number) => {
  await client.delete(`/${stimulusId}/features/${featureId}/tags/${tagId}`);
};

export const addTag2 = async (stimulusId: number, featureId: number, tag: Tag2): Promise<number> => {
  const response = await client.post(`/${stimulusId}/features/${featureId}/tags2/${tag.key.id}/${tag.value.id}`);

  return response.data.tag_feature_id;
};

export const deleteTag2 = async (stimulusId: number, featureId: number, tagId: number) => {
  await client.delete(`/${stimulusId}/features/${featureId}/tags2/${tagId}`);
};

export const getTag2Keys = async (): Promise<Tag2Key[]> => {
  const response: Tag2KeysResponse = (
    await client.get('/', {
      baseURL: tags2Endpoint,
    })
  ).data;

  return response.map((key: Tag2KeyResponse) => {
    return {
      id: key.id,
      key: key.key,
    };
  });
};

export const getTag2Values = async (keyId: number): Promise<Tag2Value[]> => {
  const response: Tag2ValuesResponse = (
    await client.get(`/${keyId}/values`, {
      baseURL: tags2Endpoint,
    })
  ).data;

  return response.map((value: Tag2ValueResponse) => {
    return {
      id: value.id,
      keyId: value.key_id,
      value: value.value,
    };
  });
};
