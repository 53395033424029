import React from 'react';

import './VideoControls.sass';

import PlayIcon from 'mdi-react/PlayIcon';
import MenuLeftIcon from 'mdi-react/MenuLeftIcon';
import MenuRightIcon from 'mdi-react/MenuRightIcon';
import PauseIcon from 'mdi-react/PauseIcon';
import { getVideoDurationString } from '../../utils';

import { VideoControlsProps } from './VideoControls.types';

const VideoControls = ({
  videoPaused,
  videoTime,
  videoDuration,
  onPause,
  onPlay,
  onStepBack,
  onStepForward,
  onTimeUpdate,
}: VideoControlsProps) => {
  return (
    <div className='video-controls'>
      <MenuLeftIcon id='step-back-button' className='control' onClick={onStepBack} />
      {!videoPaused && <PauseIcon id='pause-button' className='control' onClick={onPause} />}
      {videoPaused && <PlayIcon id='play-button' className='control' onClick={onPlay} />}
      <MenuRightIcon id='step-forward-button' className='control' onClick={onStepForward} />
      <span className='time'>
        {getVideoDurationString(videoTime)} / {getVideoDurationString(videoDuration)}
      </span>
      <input
        id='video-range'
        type='range'
        min={0}
        max={videoDuration}
        value={videoTime}
        step={0.1}
        onChange={(event) => onTimeUpdate(Number(event.target.value))}
      />
    </div>
  );
};

export default VideoControls;
