import { fabric } from 'fabric';
import React, { useCallback } from 'react';
import { DrawingMode, Point, Size } from '../../../types';
import { useFabricCanvasEvent } from '../fabric';

export const useRectangleTool = (
  canvas: fabric.Canvas | null,
  drawingMode: DrawingMode,
  enforceMinimumSize: boolean,
  snapToGrid: boolean,
  size: Size,
  originalX: React.MutableRefObject<number>,
  originalY: React.MutableRefObject<number>,
  isDown: React.MutableRefObject<boolean>,
  onFeatureAdded: (geometry: Point[]) => void,
) => {
  const onMouseDown = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas || drawingMode !== DrawingMode.Rectangle) return;

      let { x, y } = canvas.getPointer(event.e);

      x = Number(snapToGrid ? Math.round(x) : x.toFixed(2));
      y = Number(snapToGrid ? Math.round(y) : y.toFixed(2));

      x = Math.min(Math.max(x, 0), size.width!);
      y = Math.min(Math.max(y, 0), size.height!);

      const rect = new fabric.Rect({
        left: originalX.current,
        top: originalY.current,
        width: x - originalX.current,
        height: y - originalY.current,
        selectable: false,
        hasRotatingPoint: false,
        hasBorders: false,
        hasControls: false,
        angle: 0,
        fill: 'rgb(0,186,219)',
        opacity: 0.5,
      });

      canvas.add(rect);
      canvas.setActiveObject(rect);
    },
    [canvas, drawingMode, originalX, originalY, size, snapToGrid],
  );

  const onMouseMove = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas || drawingMode !== DrawingMode.Rectangle) return;

      let { x, y } = canvas.getPointer(event.e);

      x = Number(snapToGrid ? Math.round(x) : x.toFixed(2));
      y = Number(snapToGrid ? Math.round(y) : y.toFixed(2));

      x = Math.min(Math.max(x, 0), size.width!);
      y = Math.min(Math.max(y, 0), size.height!);

      const rect = canvas.getActiveObject();

      if (!rect || !isDown) return;

      canvas.setCursor('crosshair');

      if (originalX.current > x) {
        rect.set({ left: Math.abs(x) });
      }
      if (originalY.current > y) {
        rect.set({ top: Math.abs(y) });
      }

      rect.set({ width: Math.abs(originalX.current - x) });
      rect.set({ height: Math.abs(originalY.current - y) });
    },
    [canvas, drawingMode, isDown, originalX, originalY, size, snapToGrid],
  );

  const onMouseUp = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas || drawingMode !== DrawingMode.Rectangle) return;

      let { x, y } = canvas.getPointer(event.e);

      x = Number(snapToGrid ? Math.round(x) : x.toFixed(2));
      y = Number(snapToGrid ? Math.round(y) : y.toFixed(2));

      x = Math.min(Math.max(x, 0), size.width!);
      y = Math.min(Math.max(y, 0), size.height!);

      const rect = canvas.getActiveObject();

      if (rect) {
        let top = Math.round(rect.top!);
        let left = Math.round(rect.left!);
        let width = Math.round(rect.getScaledWidth());
        let height = Math.round(rect.getScaledHeight());

        if (enforceMinimumSize) {
          width = Math.max(200.0, width);
          height = Math.max(200.0, height);

          top = Math.min(size.height! - 200.0, top);
          left = Math.min(size.width! - 200.0, left);
        }

        onFeatureAdded([
          { x: left, y: top },
          { x: left + width, y: top },
          { x: left + width, y: top + height },
          { x: left, y: top + height },
        ]);

        canvas.discardActiveObject();
        canvas.remove(rect);
      }
    },

    [canvas, drawingMode, onFeatureAdded, size, enforceMinimumSize, snapToGrid],
  );

  useFabricCanvasEvent(canvas, 'tool:mouse:down', onMouseDown);
  useFabricCanvasEvent(canvas, 'tool:mouse:move', onMouseMove);
  useFabricCanvasEvent(canvas, 'tool:mouse:up', onMouseUp);
};
