import WindowMaximizeIcon from 'mdi-react/WindowMaximizeIcon';
import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';

import { FloatingWindowProps } from './FloatingWindow.types';

import './FloatingWindow.sass';

export const FloatingWindow = ({ title, titleSlot, children }: FloatingWindowProps) => {
  const windowDiv = useRef<HTMLDivElement>(null);

  const [minimized, setMinimized] = useState(false);

  const [size, setSize] = useState({ width: 480, height: 600 });
  const [maxSize, setMaxSize] = useState({ width: 480, height: 600 });

  useEffect(() => {
    if (!minimized) {
      constrainSize(size);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimized]);

  const constrainSize = (size: { width: number; height: number }) => {
    if (windowDiv.current) {
      const top = windowDiv.current.getBoundingClientRect().top;
      const right = windowDiv.current.getBoundingClientRect().right;

      const parentTop = windowDiv.current.parentElement!.getBoundingClientRect().top;
      const parentRight = windowDiv.current.parentElement!.getBoundingClientRect().right;

      const maxWidth = windowDiv.current.parentElement!.clientWidth - (parentRight - right);
      const maxHeight = windowDiv.current.parentElement!.clientHeight + (parentTop - top);

      setMaxSize({
        width: maxWidth,
        height: maxHeight,
      });

      let width = size.width;
      let height = size.height;

      if (width > maxWidth) {
        width = maxWidth;
      }

      if (height > maxHeight) {
        height = maxHeight;
      }

      setSize({ width, height });
    }
  };

  //

  return (
    <Draggable
      bounds={'parent'}
      handle={'.window-handle'}
      onDrag={() => {
        if (windowDiv.current) {
          const top = windowDiv.current.getBoundingClientRect().top;
          const right = windowDiv.current.getBoundingClientRect().right;

          const parentTop = windowDiv.current.parentElement!.getBoundingClientRect().top;
          const parentRight = windowDiv.current.parentElement!.getBoundingClientRect().right;

          setMaxSize({
            width: windowDiv.current.parentElement!.clientWidth - (parentRight - right),
            height: windowDiv.current.parentElement!.clientHeight + (parentTop - top),
          });
        }
      }}
    >
      <div ref={windowDiv} className='window-container'>
        <ResizableBox
          width={!minimized ? size.width : 200}
          height={!minimized ? size.height : 24}
          resizeHandles={!minimized ? ['sw'] : []}
          onResizeStop={(_, data) => {
            setSize(data.size);
          }}
          minConstraints={[200, 100]}
          maxConstraints={[maxSize.width, maxSize.height]}
        >
          <div className='window'>
            <div className='window-handle'>
              <span className='window-title'>{title}</span>
              <span className='window-slot'>{titleSlot && !minimized && { ...titleSlot }}</span>
              <div className='window-controls'>
                {!minimized && (
                  <WindowMaximizeIcon
                    onClick={() => {
                      setMinimized(true);
                    }}
                  />
                )}
                {minimized && (
                  <WindowMaximizeIcon
                    onClick={() => {
                      setMinimized(false);
                    }}
                  />
                )}
              </div>
            </div>
            <div className='window-wrapper'>{!minimized && { ...children }}</div>
          </div>
        </ResizableBox>
      </div>
    </Draggable>
  );
};
