import React, { useEffect, useRef, useState } from 'react';
import { DrawingMode, EditMode } from '../../types';

import CursorDefaultOutlineIcon from 'mdi-react/CursorDefaultOutlineIcon';
import ShapeRectanglePlusIcon from 'mdi-react/ShapeRectanglePlusIcon';
import ShapeCirclePlusIcon from 'mdi-react/ShapeCirclePlusIcon';
import ShapePolygonPlusIcon from 'mdi-react/ShapePolygonPlusIcon';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';

import './Toolbar.sass';

import { ToolbarProps } from './Toolbar.types';

const Toolbar = ({
  id,
  name,
  drawingMode,
  editMode,
  enforceMinimumSize,
  snapToGrid,
  zoom,
  zoomRange,
  onDrawingModeChanged,
  onEditModeChanged,
  onEnforceMinimumSizeChanged,
  onSnapToGridChanged,
  onZoomChanged,
}: ToolbarProps) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onMouseDown = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node | null)) {
        setShowDropdown(false);
      }
    };

    window.addEventListener('mousedown', onMouseDown);

    return () => {
      window.removeEventListener('mousedown', onMouseDown);
    };
  });

  const changeDrawingMode = (mode: DrawingMode) => {
    if (mode !== drawingMode) {
      onDrawingModeChanged(mode);
    }
  };

  return (
    <div className='toolbar'>
      <div className='controls-container'>
        <CursorDefaultOutlineIcon
          id='tool-select'
          onClick={() => changeDrawingMode(DrawingMode.Select)}
          className={`control ${drawingMode === DrawingMode.Select ? 'active' : ''}`}
        />
        <ShapeRectanglePlusIcon
          id='tool-rectangle'
          onClick={() => changeDrawingMode(DrawingMode.Rectangle)}
          className={`control ${drawingMode === DrawingMode.Rectangle ? 'active' : ''}`}
        />
        <ShapeCirclePlusIcon
          id='tool-circle'
          onClick={() => changeDrawingMode(DrawingMode.Circle)}
          className={`control ${drawingMode === DrawingMode.Circle ? 'active' : ''}`}
        />
        <ShapePolygonPlusIcon
          id='tool-polygon'
          onClick={() => changeDrawingMode(DrawingMode.Polygon)}
          className={`control ${drawingMode === DrawingMode.Polygon ? 'active' : ''}`}
        />
        <div className='spacer' />
        <div ref={dropdownRef} className='dropdown-container'>
          <DotsVerticalIcon
            id='dropdown-toggle'
            onClick={() => {
              setShowDropdown(!showDropdown);
            }}
            className='control'
          />
          <div className='dropdown-content' style={{ display: showDropdown ? 'block' : 'none' }}>
            <div className='option-container'>
              <label htmlFor='edit-mode'>Edit mode:</label>
              <select
                id='edit-mode'
                value={editMode}
                onChange={(event) => onEditModeChanged(event.target.value as EditMode)}
              >
                <option value={EditMode.EditScale}>Edit scale</option>
                <option value={EditMode.MovePoints}>Move points</option>
              </select>
            </div>
            <div className='option-container'>
              <label htmlFor='enforce-minimum-size'>Enforce minimum size:</label>
              <input
                id='enforce-minimum-size'
                type='checkbox'
                checked={enforceMinimumSize}
                onChange={(event) => onEnforceMinimumSizeChanged(event.target.checked)}
              />
            </div>
            <div className='option-container'>
              <label htmlFor='snap-to-grid'>Snap to grid:</label>
              <input
                id='snap-to-grid'
                type='checkbox'
                checked={snapToGrid}
                onChange={(event) => onSnapToGridChanged(event.target.checked)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='zoom-container'>
        <label htmlFor='zoom'>{'Zoom: ' + (zoom * 100).toFixed() + '%'}</label>
        <input
          id='zoom'
          type='range'
          min={zoomRange.min}
          max={zoomRange.max}
          value={zoom}
          step={0.1}
          onChange={(event) => onZoomChanged(Number(event.target.value))}
        />
      </div>
      <div className='info-container'>
        <span id='info-name'>{name}</span>
        <div className='separator' />
        <span id='info-id'>ID: {id}</span>
      </div>
    </div>
  );
};

export default Toolbar;
