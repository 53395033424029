import React, { useCallback, useRef, useState } from 'react';

import './DrawingCanvas.sass';

import { DrawingCanvasProps } from './DrawingCanvas.types';
import { StimulusType } from '../../types';
import useResizeAware from 'react-resize-aware';

import PlayIcon from 'mdi-react/PlayIcon';
import MenuLeftIcon from 'mdi-react/MenuLeftIcon';
import MenuRightIcon from 'mdi-react/MenuRightIcon';
import PauseIcon from 'mdi-react/PauseIcon';

import { getVideoDurationString } from '../../utils';
import { useDrawingCanvas } from '../../hooks';
import VideoControls from '../VideoControls/VideoControls';

const DrawingCanvas = ({
  drawingMode,
  editMode,
  type,
  src,
  size,
  videoElement,
  selectedFeature,
  features,
  enforceMinimumSize,
  snapToGrid,
  zoom,
  onFeatureSelected,
  onFeatureAdded,
  onFeatureGeometrySet,
  onZoomChanged,
  onZoomRangeChanged,
}: DrawingCanvasProps) => {
  const canvasElement = useRef<HTMLCanvasElement>(null);

  const sizeReporter = useCallback(
    (target: HTMLIFrameElement | null) => {
      return type === StimulusType.Image
        ? {
            width: target != null ? target.clientWidth : 0,
            height: target != null ? target.clientHeight : 0,
          }
        : {
            width: target != null ? target.clientWidth : 0,
            height: target != null ? target.clientHeight - 32 : 0,
          };
    },
    [type],
  );

  const [canvasResizeListener, canvasSize] = useResizeAware(sizeReporter);

  const { spacePressed, isDown } = useDrawingCanvas(
    canvasElement,
    videoElement,
    canvasSize,
    drawingMode,
    editMode,
    type,
    src,
    size,
    selectedFeature,
    features,
    enforceMinimumSize,
    snapToGrid,
    zoom,
    onFeatureSelected,
    onFeatureAdded,
    onFeatureGeometrySet,
    onZoomChanged,
    onZoomRangeChanged,
  );

  return (
    <div className='drawing-canvas'>
      <div className='content-container'>
        <div className={`content${spacePressed && !isDown.current ? ' grab-cursor' : ''}`}>
          {canvasResizeListener}
          <canvas style={{ cursor: spacePressed ? 'grab !important' : 'default !important' }} ref={canvasElement} />
        </div>
      </div>
    </div>
  );
};

export default DrawingCanvas;
