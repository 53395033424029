import React, { useState } from 'react';

import { StimulusType } from '../../types';

import CursorDefaultOutlineIcon from 'mdi-react/CursorDefaultOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';

import 'react-resizable/css/styles.css';

import './FeatureList.sass';

import { FeatureListProps } from './FeatureList.types';
import { getColorFromId } from '../../utils';
import { FloatingWindow } from '../FloatingWindow/FloatingWindow';
import TagsInput from '../TagsInput/TagsInput';
import Tags2Input from '../Tags2Input/Tags2Input';

const FeatureList = ({
  stimulusType,
  selectedFeatureId,
  features,
  onFeatureSelected,
  onFeatureDeleted,
  onFeatureNameSet,
  onFeatureStartTimeSet,
  onFeatureEndTimeSet,
  onFeatureTagAdded,
  onFeatureTagDeleted,
  onFeatureTag2Added,
  onFeatureTag2Deleted,
}: FeatureListProps) => {
  const [newTagSystem, setNewTagSystem] = useState(true);

  return (
    <FloatingWindow
      title='Feature List'
      titleSlot={
        <div className='feature-tag-system-switch'>
          <label htmlFor='tag-system'>Use new tag system:</label>
          <input
            id='tag-system'
            type='checkbox'
            checked={newTagSystem}
            onChange={(event) => setNewTagSystem(event.target.checked)}
          />
        </div>
      }
    >
      <table className='feature-table'>
        <thead>
          <tr>
            <th className='name'>Name</th>
            {stimulusType === StimulusType.Video && (
              <th className='time' colSpan={2}>
                Start time
              </th>
            )}
            {stimulusType === StimulusType.Video && (
              <th className='time' colSpan={2}>
                End time
              </th>
            )}
            <th>Tags</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {features.map((feature) => (
            <tr
              key={feature.id}
              style={selectedFeatureId === feature.id ? { backgroundColor: 'rgba(0, 0, 0, 0.1)' } : {}}
            >
              <td className='name' style={{ backgroundColor: `${getColorFromId(feature.id, 0.5)}` }}>
                <input value={feature.name} onChange={(e) => onFeatureNameSet(feature.id, e.target.value)} />
              </td>
              {stimulusType === StimulusType.Video && (
                <td className='time'>
                  <span>{feature.timeInterval && `${(feature.timeInterval.start / 1000).toFixed(1)}s`}</span>
                </td>
              )}
              {stimulusType === StimulusType.Video && (
                <td className='button'>
                  <button className='set-button' onClick={() => onFeatureStartTimeSet(feature.id)}>
                    SET
                  </button>
                </td>
              )}
              {stimulusType === StimulusType.Video && (
                <td className='time'>
                  <span>{feature.timeInterval && `${(feature.timeInterval.end / 1000).toFixed(1)}s`}</span>
                </td>
              )}
              {stimulusType === StimulusType.Video && (
                <td className='button'>
                  <button className='set-button' onClick={() => onFeatureEndTimeSet(feature.id)}>
                    SET
                  </button>
                </td>
              )}
              {!newTagSystem && (
                <td>
                  <TagsInput
                    tags={feature.tags}
                    onTagAdded={(tag) => onFeatureTagAdded(feature.id, tag)}
                    onTagDeleted={(tag) => onFeatureTagDeleted(feature.id, tag.id!)}
                  />
                </td>
              )}
              {newTagSystem && (
                <td>
                  <Tags2Input
                    tags={feature.tags2}
                    onTagAdded={(tag) => onFeatureTag2Added(feature.id, tag)}
                    onTagDeleted={(tag) => onFeatureTag2Deleted(feature.id, tag.id!)}
                  />
                </td>
              )}
              <td className='actions'>
                <div className='actions-container'>
                  <CursorDefaultOutlineIcon
                    className={`control ${selectedFeatureId === feature.id ? 'active' : ''}`}
                    onClick={() => onFeatureSelected(feature.id)}
                  />
                  <DeleteIcon className='control-delete' onClick={() => onFeatureDeleted(feature.id)} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </FloatingWindow>
  );
};

export default FeatureList;
