import { fabric } from 'fabric';
import { getColorFromId } from '../utils';

export * from './api';

export type Stimulus = {
  id: number;
  name: string;
  type: StimulusType;
  source: string;
  size: Size;
  features: Feature[];
};

export enum StimulusType {
  Video,
  Image,
}

export type Size = {
  width: number;
  height: number;
};

export type Feature = {
  id: number;
  name: string;
  tags: Tag[];
  tags2: Tag2[];
  geometry: Point[];
  timeInterval?: TimeInterval;
};

export type Point = {
  x: number;
  y: number;
};

export type Tag = {
  id?: number;
  key: string;
  value: string;
};

export type Tag2 = {
  id?: number;
  key: Tag2Key;
  value: Tag2Value;
};

export type Tag2Key = {
  id: number;
  key: string;
};

export type Tag2Value = {
  id: number;
  keyId: number;
  value: string;
};

export type TimeInterval = {
  start: number;
  end: number;
};

export enum DrawingMode {
  Select = 'Select',
  Rectangle = 'Rectangle',
  Circle = 'Circle',
  Polygon = 'Polygon',
}

export enum EditMode {
  EditScale = 'EditScale',
  MovePoints = 'MovePoints',
}

export class FeaturePolygon extends fabric.Polygon {
  feature: Feature;

  constructor(feature: Feature) {
    super(feature.geometry, {
      fill: getColorFromId(feature.id),
      // TODO: We might want to enable rotation
      lockRotation: true,
      opacity: 0.5,
    });

    // TODO: We might want to enable rotation
    this.setControlVisible('mtr', false);

    this.feature = feature;
  }

  geometry(snapToGrid: boolean = true): Point[] {
    const matrix = this.calcTransformMatrix();

    return this.points!.map((p) => new fabric.Point(p.x - this.pathOffset.x, p.y - this.pathOffset.y))
      .map((point) => fabric.util.transformPoint(point, matrix))
      .map((point) => {
        return {
          x: Number(snapToGrid ? Math.round(point.x) : point.x.toFixed(2)),
          y: Number(snapToGrid ? Math.round(point.y) : point.y.toFixed(2)),
        };
      });
  }
}
