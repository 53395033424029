import { RefObject, useEffect, useState } from 'react';
import { fabric } from 'fabric';

export const useFabricCanvas = (
  canvasElement: RefObject<HTMLCanvasElement>,
  canvasSize: { width: number; height: number },
) => {
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);

  // Init canvas (or re-init) when canvasElement changes
  useEffect(() => {
    if (!canvasElement.current) return;

    fabric.Object.prototype.objectCaching = false;

    const fabricCanvas = new fabric.Canvas(canvasElement.current, {
      stopContextMenu: true,
    });

    setCanvas(fabricCanvas);

    return () => {
      fabricCanvas.dispose();
    };
  }, [canvasElement]);

  // Handle resize
  useEffect(() => {
    if (!canvas) return;

    canvas.setDimensions({
      width: canvasSize.width,
      height: canvasSize.height,
    });
  }, [canvas, canvasSize]);

  return canvas;
};

export const useFabricCanvasEvent = (
  canvas: fabric.Canvas | null,
  event: string,
  handler: (e: fabric.IEvent) => void,
) => {
  useEffect(() => {
    if (!canvas) return;

    canvas.on(event, handler);

    return () => {
      canvas.off(event, handler);
    };
  }, [canvas, event, handler]);
};
