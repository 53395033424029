import { useEffect, useState } from 'react';
import { Feature, Point, Tag } from '../types';

export const useCopyPasteFeature = (
  selectedFeatureId: number | null,
  features: Feature[],
  addFeature: (geometry: Point[], tags?: Tag[] | undefined) => Promise<void>,
) => {
  const [clipboardFeature, setClipboardFeature] = useState<Feature | null>();

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'c') {
        if (selectedFeatureId) {
          const currentFeature = features.find((feature) => feature.id === selectedFeatureId);

          if (currentFeature) {
            setClipboardFeature({
              ...currentFeature,
              tags: [
                ...currentFeature.tags.map((tag) => ({
                  ...tag,
                })),
              ],
              geometry: [
                ...currentFeature.geometry.map((point) => ({
                  ...point,
                })),
              ],
              timeInterval: currentFeature.timeInterval
                ? {
                    ...currentFeature.timeInterval,
                  }
                : undefined,
            });
          }
        }
      }

      if ((event.metaKey || event.ctrlKey) && event.key === 'v') {
        if (clipboardFeature) {
          addFeature(clipboardFeature.geometry, clipboardFeature.tags);
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [clipboardFeature, selectedFeatureId, features, addFeature]);
};
