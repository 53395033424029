import { fabric } from 'fabric';
import { useCallback, useEffect } from 'react';
import { Size } from '../../types';
import { constrainViewport } from '../../utils';
import { useFabricCanvasEvent } from './fabric';

export const useZoom = (
  canvas: fabric.Canvas | null,
  canvasSize: { width: number; height: number },
  size: Size,
  zoom: number,
  onZoomChanged: (zoom: number) => void,
  onZoomRangeChanged: (zoomRange: { min: number; max: number }) => void,
) => {
  const onMouseWheel = useCallback(
    (event: fabric.IEvent) => {
      if (!canvas) return;

      // @ts-ignore
      const delta = event.e.deltaY;
      let zoom = canvas.getZoom();

      const defaultZoom = size.height > size.width ? canvasSize.height / size.height : canvasSize.width / size.width;

      zoom *= 0.999 ** delta;
      if (zoom > defaultZoom * 20) zoom = defaultZoom * 20;
      if (zoom < defaultZoom * 0.5) zoom = defaultZoom * 0.5;

      // @ts-ignore
      canvas.zoomToPoint(new fabric.Point(event.e.offsetX, event.e.offsetY), zoom);

      canvas.fire('viewport:zoom', event);
      constrainViewport(canvas, canvasSize, size);

      onZoomChanged(zoom);
    },
    [canvas, canvasSize, onZoomChanged, size],
  );

  useFabricCanvasEvent(canvas, 'mouse:wheel', onMouseWheel);

  useEffect(() => {
    if (!canvas) return;

    canvas.zoomToPoint(new fabric.Point(canvasSize.width / 2, canvasSize.height / 2), zoom);

    constrainViewport(canvas, canvasSize, size);
  }, [canvas, canvasSize, size, zoom]);

  useEffect(() => {
    if (!canvas) return;

    if (size.height > size.width) {
      canvas.setZoom((canvasSize.height / size.height) * 0.75);
    } else {
      canvas.setZoom((canvasSize.width / size.width) * 0.75);
    }

    const matrix = fabric.util.qrDecompose(canvas.viewportTransform!);

    canvas.setViewportTransform(
      fabric.util.composeMatrix({
        angle: 0,
        scaleX: matrix.scaleX,
        scaleY: matrix.scaleY,
        flipX: false,
        flipY: false,
        skewX: 0,
        skewY: 0,
        translateX: canvas.width! / 2 - (size.width / 2) * matrix.scaleX,
        translateY: canvas.height! / 2 - (size.height / 2) * matrix.scaleY,
      }),
    );

    const defaultZoom = size.height > size.width ? canvasSize.height / size.height : canvasSize.width / size.width;

    onZoomRangeChanged({
      min: defaultZoom * 0.5,
      max: defaultZoom * 20,
    });

    onZoomChanged(defaultZoom * 0.75);
  }, [canvas, canvasSize, onZoomChanged, onZoomRangeChanged, size]);
};
