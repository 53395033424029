import { fabric } from 'fabric';
import { Size } from '../types';

export const getRegularPolygonPoints = (sideCount: number, radiusX: number, radiusY: number) => {
  const sweep = (Math.PI * 2) / sideCount;
  const cx = radiusX;
  const cy = radiusY;
  const points = [];
  for (let i = 0; i < sideCount; i++) {
    const x = cx + radiusX * Math.cos(i * sweep);
    const y = cy + radiusY * Math.sin(i * sweep);
    points.push({ x: x, y: y });
  }
  return points;
};

export const constrainViewport = (canvas: fabric.Canvas, canvasSize: Size, size: Size) => {
  let matrix = fabric.util.qrDecompose(canvas.viewportTransform!);

  const topBound = matrix.translateY;
  const bottomBound = -canvasSize.height - (matrix.translateY / matrix.scaleY + size.height) * -matrix.scaleY;

  if (size.height * matrix.scaleY > canvasSize.height) {
    if (topBound > 0) {
      canvas.relativePan(new fabric.Point(0, -topBound));
    }

    if (bottomBound < 0) {
      canvas.relativePan(new fabric.Point(0, -bottomBound));
    }
  } else {
    canvas.setViewportTransform(
      fabric.util.composeMatrix({
        angle: 0,
        scaleX: matrix.scaleX,
        scaleY: matrix.scaleY,
        flipX: false,
        flipY: false,
        skewX: 0,
        skewY: 0,
        translateX: matrix.translateX,
        translateY: canvas.height! / 2 - (size.height / 2) * matrix.scaleY,
      }),
    );
  }

  matrix = fabric.util.qrDecompose(canvas.viewportTransform!);

  const leftBound = matrix.translateX;
  const rightBound = -canvasSize.width - (matrix.translateX / matrix.scaleX + size.width) * -matrix.scaleX;

  if (size.width * matrix.scaleX > canvasSize.width) {
    if (leftBound > 0) {
      canvas.relativePan(new fabric.Point(-leftBound, 0));
    }

    if (rightBound < 0) {
      canvas.relativePan(new fabric.Point(-rightBound, 0));
    }
  } else {
    canvas.setViewportTransform(
      fabric.util.composeMatrix({
        angle: 0,
        scaleX: matrix.scaleX,
        scaleY: matrix.scaleY,
        flipX: false,
        flipY: false,
        skewX: 0,
        skewY: 0,
        translateX: canvas.width! / 2 - (size.width / 2) * matrix.scaleX,
        translateY: matrix.translateY,
      }),
    );
  }
};
